<div class="col-md-12">
  <container-element [ngSwitch]="pageview">
    <some-element *ngSwitchCase="'table'">
      <div class="main-card mb-3 card">
        <div class="card-header">
          <div class="card-header-title font-size-lg text-capitalize fw-Bold">
            <b style="color: blue;">Create Invoice</b>
          </div>
        </div>
        <div class="row p-4">
          <div class="col-md-2 row">
            <mat-form-field appearance="standard">
              <mat-label>Select Customer Type</mat-label>
              <mat-select matNativeControl [(ngModel)]="customerType" (ngModelChange)="onCustomerTypeChange()">
                <mat-option value="customer">Corporate Customers</mat-option>
                <mat-option value="retail">Retail Customers</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-md-2 row">
            <mat-form-field appearance="standard">
              <mat-label>Customer Name</mat-label>
              <mat-select matNativeControl id="" [(ngModel)]="sender_id" (selectionChange)="onCustomerSelected()">
                <mat-option value="">All</mat-option>
                <ng-container *ngIf="customerType === 'customer'; else retailCustomersSection">
                  <mat-option *ngFor="let customer of corporateCustomers" [value]="customer.id">
                    {{ customer.firstName }}
                  </mat-option>
                </ng-container>
                <ng-template #retailCustomersSection>
                  <mat-option *ngFor="let customer of individualCustomers" [value]="customer.id">
                    {{ customer.firstName }}
                  </mat-option>
                </ng-template>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-md-2 row">
            <mat-form-field appearance="standard">
              <input matInput [matDatepicker]="fromDate" placeholder="From Date"
                (dateChange)="onDateChange('fromDate', $event)" [formControl]="filterForm.get('fromDate')" [matDatepickerFilter]="fDateFilter" readonly>
              <mat-datepicker-toggle matSuffix [for]="fromDate"></mat-datepicker-toggle>
              <mat-datepicker #fromDate></mat-datepicker>
            </mat-form-field>
          </div>
          <div class="col-md-2 row">
            <mat-form-field appearance="standard">
              <input matInput [matDatepicker]="toDate" placeholder="To Date"
                (dateChange)="onDateChange('toDate', $event)" [formControl]="filterForm.get('toDate')" [matDatepickerFilter]="dateFilter" readonly>
              <mat-datepicker-toggle matSuffix [for]="toDate"></mat-datepicker-toggle>
              <mat-datepicker #toDate></mat-datepicker>
            </mat-form-field>
          </div>
          <div class="col-md-2 row" style="margin-top: 11.5px; width: 150px">
            <mat-form-field appearance="legacy">
              <mat-label>Product Type</mat-label>
              <mat-select [(ngModel)]="productType" (ngModelChange)="onProductFilter()">
                <mat-option *ngFor="let type of productTypes" [value]="type">
                  {{ type }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-md-2 row" style="margin-top: 11.5px; width: 150px">
            <mat-form-field appearance="legacy">
              <mat-label>Product Mode</mat-label>
              <mat-select [(ngModel)]="productMode" (ngModelChange)="onProductFilter()" >
                <mat-option *ngFor="let modes of productModes" [value]="modes.mode">
                  {{ modes.mode }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="col-md-2 row" style="margin-top: 11.5px; width: 130px">
            <mat-form-field appearance="legacy" style="width: 105px">
              <mat-label>Weight From</mat-label>
              <input matInput placeholder="Weight From" [(ngModel)]="weightFrom" (ngModelChange)="onWeightChange()">
            </mat-form-field>
          </div>
          <div class="col-md-2 row" style="margin-top: 11.5px; width: 130px">
            <mat-form-field appearance="legacy" style="width: 105px">
              <mat-label>Weight To</mat-label>
              <input matInput placeholder="Weight To" [(ngModel)]="weightTo" (ngModelChange)="onWeightChange()">
            </mat-form-field>
          </div>
<!--          <div class="col-lg-2 col-md-1  row m-1">-->
<!--            <button class="btn btn-secondary m-auto" (click)="resetForm()">-->
<!--              Reset-->
<!--            </button>-->
<!--          </div>-->
          <!-- <div class="col-lg-2 col-md-2 row">
            <button class="btn btn-primary m-auto" (click)="tablefilter()"> Find
              <i class="pe-7s-search"></i></button>
          </div> -->
          <div class="btn-actions-pane-left p-2">
              <button class="mb-2 me-2 btn btn-primary" (click)="generateInvoicesPreview()" *ngIf="selectedInvoiceIds.length > 0">
                Invoice preview
              </button>
              <button class="mb-2 me-2 btn btn-success" (click)="generateInvoices()" *ngIf="selectedInvoiceIds.length > 0">
                Generate Invoices
              </button>
              <button class="mb-2 me-2 btn btn-secondary" (click)="resetForm()" style="width: 130px">
                Reset
              </button>
          </div>
        </div>
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <div>
                <ngx-loading [show]="loading"
                  [config]="{animationType: ngxLoadingAnimationTypes.cubeGrid, primaryColour: primaryColour, secondaryColour: secondaryColour, backdropBorderRadius: '3px'}"
                  [template]="loadingTemplate"></ngx-loading>
                <ng-container *ngIf="invoices?.length > 0; else noRecordsMessage">
                  <table *ngIf="invoices?.length > 0" class="w-100" mat-table [dataSource]="invoices" matSort>
                    <ng-container matColumnDef="select">
                      <th mat-header-cell *matHeaderCellDef>
                        <mat-checkbox #selectAllCheckbox (change)="selectAll($event)"></mat-checkbox>
                      </th>
                      <td mat-cell *matCellDef="let invoice">
                        <mat-checkbox  #checkboxElem (change)="toggleSelection(invoice, checkboxElem)"
                          [checked]="isSelected(invoice.id)"></mat-checkbox>
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="consignmentNo">
                      <th class="p-1" mat-header-cell *matHeaderCellDef
                          sortActionDescription="Sort by number" style="width: 10%"><span *ngIf="selectedRows.length>0" style="color: #5b7fdc"><b>({{ selectedRows.length }})</b></span> C.No </th>
                      <td mat-cell *matCellDef="let invoice" class="p-1"><a [routerLink]=""
                          (click)="gotoConsignmentView(invoice.id,invoice.consignment_no)">
                          {{invoice.consignment_no}}
                        </a></td>
                    </ng-container>
                    <ng-container matColumnDef="customerName">
                      <th mat-header-cell *matHeaderCellDef  style="width: 15%">Customer Name</th>
                      <td mat-cell *matCellDef="let invoice">{{ invoice.isRetailCustomer == true?
                        invoice.individual_name:invoice.customer_name}}</td>
                    </ng-container>
                    <ng-container matColumnDef="origin">
                      <th mat-header-cell *matHeaderCellDef  style="width: 10%">Origin</th>
                      <td mat-cell *matCellDef="let invoice">{{ invoice.cityname }}</td>
                    </ng-container>
                    <ng-container matColumnDef="destination">
                      <th mat-header-cell *matHeaderCellDef  style="width: 10%">Destination</th>
                      <td mat-cell *matCellDef="let invoice">{{ invoice.receivercity }}</td>
                    </ng-container>
                    <ng-container matColumnDef="productType">
                      <th mat-header-cell *matHeaderCellDef  style="width: 9%">Product Type</th>
                      <td mat-cell *matCellDef="let invoice">{{ invoice.product_type }}</td>
                    </ng-container>
                    <ng-container matColumnDef="productMode">
                      <th mat-header-cell *matHeaderCellDef  style="width: 9%">Product Mode</th>
                      <td mat-cell *matCellDef="let invoice">{{ invoice.product_mode }}</td>
                    </ng-container>
                    <ng-container matColumnDef="totalWeight">
                      <th mat-header-cell *matHeaderCellDef  style="width: 10%">Total Weight</th>
                      <td mat-cell *matCellDef="let invoice">{{ invoice.totalWeight }}</td>
                    </ng-container>
                    <ng-container matColumnDef="isInvoiceGenerated">
                      <th mat-header-cell *matHeaderCellDef  style="width: 10%">Status</th>
                      <td mat-cell *matCellDef="let invoice"
                        [ngStyle]="{ 'color': invoice.billingTaxableAmount ==0 || invoice.billingTaxableAmount ==null   ? 'red' :  invoice.costTaxableAmount == 0 || invoice.costTaxableAmount == null ? 'red':'#FFBF00' }">
                        {{ invoice.billingTaxableAmount ==0 || invoice.billingTaxableAmount ==null ? 'No Billing Info' :
                        invoice.costTaxableAmount == 0 || invoice.costTaxableAmount == null? ' No Cost Info' : " Not invoice" }}
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="createdAt">
                      <th mat-header-cell *matHeaderCellDef  style="width: 15%">Created At</th>
                      <td mat-cell *matCellDef="let invoice">{{ invoice.created_at | date: 'MMM d, y, h:mm a' }}</td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let invoice; columns: displayedColumns;"></tr>
                  </table>
                </ng-container>
                <mat-paginator [length]="totalItems" [pageIndex]="pageNo" [pageSizeOptions]="pageSizeOptions"
                  [pageSize]="pageSize" (page)="onPaginateChange($event)" showFirstLastButtons>
                </mat-paginator>
                <ng-template #noRecordsMessage>
                  <h1 class="text-center fw-bold">No Records Available..!</h1>
                </ng-template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </some-element>
  </container-element>
</div>
